import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VAutocomplete,{attrs:{"no-data-text":_vm.$t('general.fields.noDataAvailable'),"small":"","color":"secondary","label":_vm.$t('company.fields.selectCompanies'),"multiple":"","clearable":"","item-color":"secondary","items":_vm.companies,"item-text":"name","item-value":"id","rules":_vm.requireRules,"disabled":!(_vm.companies && _vm.companies.length > 0),"return-object":"","small-chips":""},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c(VChip,{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item.name.toUpperCase()))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.selectedCompanies.length - 1)+" "+_vm._s(_vm.$t('general.titles.others'))+") ")]):_vm._e()]}},{key:"prepend-item",fn:function(){return [_c(VListItem,[_c(VListItemContent,{staticClass:"py-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 py-0 text-right"},[_c(VBtn,{attrs:{"small":"","color":"secondary","x-small":"","text":""},on:{"click":function($event){_vm.selectedCompanies=_vm.companies}}},[_vm._v(" "+_vm._s(_vm.$t('general.titles.selectAll'))+" ")])],1),_c(VDivider,{attrs:{"vertical":""}}),_c('div',{staticClass:"col-5 py-0 text-left"},[_c(VBtn,{attrs:{"small":"","color":"secondary","x-small":"","text":""},on:{"click":function($event){_vm.selectedCompanies=[]}}},[_vm._v(" "+_vm._s(_vm.$t('general.titles.clean'))+" ")])],1)],1)])],1),_c(VDivider,{staticClass:"mb-2"})]},proxy:true}]),model:{value:(_vm.selectedCompanies),callback:function ($$v) {_vm.selectedCompanies=$$v},expression:"selectedCompanies"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }